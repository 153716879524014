import React from 'react';
import Layout from '../components/layout';

const FourZeroFour = () => (
  <Layout>
    <p>Whoops! Page Not Found</p>
  </Layout>
);

export default FourZeroFour;

